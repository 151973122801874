










































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      images: "images",
      oss: "oss",
      url: "url"
    }),
    titleList(): string[] {
      return ["CYBERBOARD", "AM AFA", "AM COMPACT TOUCH", "CYBERMAT"];
    }
  },
  methods: {
    open(url: string) {
      window.open(url, "_blank");
    }
  }
});
